import Util from "@/utils/utils";
import { getList, getExport } from "./api";

export default {
  name: "terminalInspectionRecord",
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/threeQualityManagement/thirdQradeReceiving",
          name: "thirdQradeReceiving",
          title: "终包采购计划"
        }
      ],
      hideOrShowSize: true,
      columns: [
        {
          label: "计划编码",
          prop: "code",
          width: 250,
        },
        {
          label: "计划名称",
          prop: "name",
          width: 250,
        },
        {
          label: "提报单位名称",
          prop: "submitOrgName",
          width: 250,
        },
        {
          label: "商家编码",
          prop: "shopShopCode",
          width: 250,
        },
        {
          label: "商家名称",
          prop: "shopName",
          width: 250,
        },
        {
          label: '类型',
          prop: 'shopTypeName',
          width: 250,
        },
        {
          label:'状态',
          prop:'statusName',
          width:250
        },
        {
          label: '计划发布时间',
          prop: 'submitDate',
          width: 250,
        },
        {
          label: '计划截止时间',
          prop: 'endDate',
          width: 250,
        },
        {
          label: "提报人",
          prop: 'submitUserRealName',
          width: 250,
        },
        {
          label: "备注",
          prop: 'remark',
          width: 250,
        },
        {
          label: "操作",
          slot: "btnClick",
          width: 150,
          fixed: 'right',

        },
      ],
      tableData: [],
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      ssearchList: {
      },
      searchNumber: 0,
      hideshow: false,
      isLoading: false,
      onluyOneMessage: false,

      tableLoading: false
    };
  },
  watch: {
    "ssearchList.consignee": function (val) {
      this.ssearchList.consignee = val.trim();
    },

  },
  mounted() {

    this.tablelist();
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  methods: {

    exportClick() {
      let data = {
        ...this.ssearchList
      }
      this.isLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "终包采购计划提报.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .finally(res => {
          this.isLoading = false;
        });
    },
    loadMore() {
      this.hideshow = !this.hideshow;
    },

    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.tablelist();
    },
    searchFun(arg) {
      this.pager.pageNo = 1
      this.ssearchList = {
        ...this.ssearchList,
        ...arg[0]
      };
      this.tablelist();
    },
    // 获取列表数据
    tablelist() {
      let data = {
        page: this.pager.pageNo,
        rows: this.pager.pageSize,
        ...this.ssearchList
      }
      this.tableLoading = true
      getList(data).then(res => {
        if (res.data.success) {
          if(  res.data.data) {
            this.tableData = res.data.data;
            if (res.data.data) {
              this.searchNumber = res.data.count;
              this.pager.count = res.data.count;
            }
            if(this.tableData && this.tableData.length > 0) {
                 // 表格数据重组
              this.tableData.forEach(tableItem => {
                tableItem.costCompanyCisCode = tableItem.costCompany.cisCode
                tableItem.costCompanyName = tableItem.costCompany.name
                tableItem.submitOrgCisCode = tableItem.submitOrg.cisCode
                tableItem.submitOrgName = tableItem.submitOrg.name
                tableItem.shopShopCode = tableItem.shop.shopCode
                tableItem.shopName = tableItem.shop.name
                tableItem.shopTypeName = tableItem.shop.type == '0'?'门店':'商家'
                tableItem.submitUserUserName = tableItem.submitUser.userName
                tableItem.submitUserRealName = tableItem.submitUser.realName
                tableItem.statusName = tableItem.status == '0'?'待提报':'已提报'
              })
            }
          } else {
            this.tableData = []
          }
          this.tableLoading = false
        } else {
          this.$message.warning(res.data.msg)
          this.tableLoading = false
        }

      });
    },


    // 查看详情
    toDetail(row) {
      let routeUrl = this.$router.resolve({
        path: "/threeQualityManagement/thirdQradeReceivingDetail",
        query: { taskId: row.taskId, id:row.id },
      });
      window.open(routeUrl.href, "_blank");
    },

    hideOrShow(data) {
      this.hideOrShowSize = data;
    },
    handleChange(e) {},
    onChangeEndTime(e, time) {
      this.ssearchList.createTime = time[0] + '_' + time[1];
    },
    searchClick() {
      this.tablelist();
    }
  }
};
